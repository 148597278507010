


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.redDotBox {
  width: 8rem;
  margin-left: 13px;
  padding: 19px 0;
  color: #d90119;
  cursor: pointer;
  /deep/ .el-badge__content.is-dot {
    height: 8px !important;
  }
}

.priceNotice {
  display: flex;
  height: 500px;
  // border: 1px solid #c3c3c3;
  .priceTime {
    width: 260px;
    position: relative;
background: #F5F5F5;
padding:0;
    .priceTimeContent {
      padding: 17px 23px;
      cursor: pointer;
      color:#333333;
      .priceDotNum {
        width: 30px;
        color: #fff;
        background: #FF6B4B;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
    .priceTimeContentTrue {
      background: #FAFAFA;
      color:#666666;
    }
    .pageNum {
      position: absolute;
      bottom: 10px;
      right: 0;
    }
  }
  .priceContent {
    flex: 1;
    padding: 20px;
    background:#FAFAFA;
    > div {
      background: #FFFFFF;
      margin-bottom: 20px;
      border-radius: 3px;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.dialogPrice /deep/ .el-dialog__body {
  padding:0;
  background: #FAFAFA;
}
/deep/ .dfOn{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 18px;
}
/deep/ .dialogTit{
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
}
/deep/ .dialogContent .el-form-item{
  margin-bottom: 10px;
  color: #f56c6c;
}
::-webkit-scrollbar  {
  width: 0;
}
::-webkit-scrollbar-thumb {
  background: transparent;
  width:0;
}
